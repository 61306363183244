import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";
import { ZoneService } from '../../zone-layout/_service/zone.service';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  public APIEndpoint = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private _zoneService: ZoneService
  ) { }

  /**
   * Method         : getOfficeList
   * Description    : Get Office List by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getOfficeList() {
    return this.http.get(this.APIEndpoint+'api/auth/office')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

 /**
   * Method         : getViewableOfficeList
   * Description    : Get viewablw Office List by Admin
   * Date           : 2021-11-15
   * Author         : Saikat Pal(Appsbee)
 */
  public getViewableOfficeList() {
    return this.http.get(this.APIEndpoint+'api/auth/office/primary-viewable-list')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : postOfficeDetails
   * Description    : Add Office by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public postOfficeDetails(params, uploadedFile) {
    const formData = new FormData();
    formData.append('office_name', params.office_name);
    formData.append('display_name', params.display_name);
    formData.append('address', params.address);
    formData.append('city', params.city);
    formData.append('state', params.state);
    formData.append('zip', params.zip);
    formData.append('contact_number', params.contact_number);
    formData.append('contact_email', params.contact_email);
    formData.append('zone_id', params.zone_id);
    formData.append('tax', params.tax);
    formData.append('licence', params.licence);
    formData.append('website', params.website);
    formData.append('fax', params.fax);
    if(params.filename !== ''){
      formData.append('officeLogoImage', params.filename);
      formData.append('logo', uploadedFile, uploadedFile.name);
    }
    return this.http.post(this.APIEndpoint+'api/auth/office', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getStateList
   * Description    : Get State List by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getStateList() {
    return this.http.get(this.APIEndpoint+'api/auth/state-list')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getOfficeDetails
   * Description    : Get Office Details by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getOfficeDetails(officeId) {
    return this.http.get(this.APIEndpoint+'api/auth/office/' + officeId)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateOfficeDetails
   * Description    : Update Office Details by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public updateOfficeDetails(params, uploadedFile) {
    console.log(params);
    const formData = new FormData();
    formData.append('office_name', params.office_name);
    formData.append('display_name', params.display_name);
    formData.append('address', params.address);
    formData.append('city', params.city);
    formData.append('state', params.state);
    formData.append('zip', params.zip);
    formData.append('contact_number', params.contact_number);
    formData.append('contact_email', params.contact_email);
    formData.append('is_active', params.is_active);
    formData.append('id', params.id);
    formData.append('zone_id', params.zone_id);
    formData.append('tax', params.tax);
    formData.append('licence', params.licence);
    formData.append('website', params.website);
    formData.append('lat', params.lat);
    formData.append('lng', params.lng);
    if(params.fax !== ''){
      formData.append('fax', params.fax);
    } else {
      formData.append('fax', '');
    }
    if(params.filename !== ''){
      formData.append('officeLogoImage', params.filename);
      formData.append('logo', uploadedFile, uploadedFile.name);
    }
    return this.http.post(this.APIEndpoint+'api/auth/office/update-office', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deletoffice
   * Description    : Delete Office by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public deletoffice(id) {
    const formData = new FormData();
    formData.append('id', id);
    return this.http.post(this.APIEndpoint+'api/auth/office/delete-office', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * zoneList
   */
  public zoneList() {
    return this._zoneService.getzoneList();
  }

  public getAllOfficeList() {
    return this.http.get(this.APIEndpoint + 'api/auth/get-all-office')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
