import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader = new BehaviorSubject<string>('hideloader');
  castLoader = this.loader.asObservable();

  constructor() { }

  /**
   * showLoader
   */
  public showLoader() {
    this.loader.next('showloader');
  }

  /**
   * hideLoader
   */
  public hideLoader() {
    this.loader.next('hideloader');
  }

  /**
   * reloadOfficelist
   */
  public reloadOfficeList() {
    this.loader.next('reloadOfficeList');
  }

  /**
   * changeOffice
   */
  public changeOffice() {
    this.loader.next('changeOffice');
  }

  /**
   * diffOfficeCustomer
   */
  public diffOfficeCustomer() {
    this.loader.next('diffOfficeCustomer');
  }

  /**
   * customerDetail
   */
  public customerDetail() {
    this.loader.next('customerDetail');
  }

  public editMap(msg){
    this.loader.next(msg);
  }

  public addedSubscription(){
    this.loader.next('addedSubscription');
  }

  public addedAppointment(){
    this.loader.next('addedAppointment');
  }

  public updateSubscription(){
    this.loader.next('updateSubscription');
  }
  
  public updateAppointment(){
    this.loader.next('updateAppointment');
  }

  public SelectedSubscription(){
    this.loader.next('SelectedSubscription');
  }
  
  public SelectedAppointment(){
    this.loader.next('SelectedAppointment');
  }

  public onSaveSubscription(){
    this.loader.next('saveSubscription');
  }

}
