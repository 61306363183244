import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  public APIEndpoint = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  getServiceTypeList(){
    return this.http.get(this.APIEndpoint+'api/auth/servicetype')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public postServiceTypeDetails(params) {
    return this.http.post(this.APIEndpoint+'api/auth/servicetype', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }



  /**
   * Method         : getOfficeDetails
   * Description    : Get Office Details by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getServiceTypeDetails(officeId) {
    return this.http.get(this.APIEndpoint+'api/auth/servicetype/' + officeId)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateOfficeDetails
   * Description    : Update Office Details by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public updateServiceTypeDetails(params) {
    // const formData = new FormData();
    // formData.append('name', params.name);
    // formData.append('description', params.description);
    // formData.append('frequency', params.frequency);
    // formData.append('default_charge', params.default_charge);
    // formData.append('is_active', params.activeStatus);
    // formData.append('id', params.id);
    // formData.append('serviceType', params.serviceType);
    // formData.append('taxable', params.taxable);
    // formData.append('initial_charge', params.initial_charge);
    // formData.append('quarterly_charge', params.quarterly_charge);
    // formData.append('appointment_count', params.appointment_count);
    return this.http.post(this.APIEndpoint+'api/auth/servicetype/update-servicetype', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deletoffice
   * Description    : Delete Office by Admin
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public deletServiceType(id) {
    const formData = new FormData();
    formData.append('id', id);
    return this.http.post(this.APIEndpoint+'api/auth/servicetype/delete-servicetype', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getservicetype
   * Description    : Service Type List
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getServiceTypes() {
    var servicetypes = ['general', 'specialty', 'Areas/Misc Add ons', 'termite'];
    return servicetypes;
  }

}
