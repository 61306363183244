import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceHeaderService {

  public APIEndpoint = environment.baseUrl;
  
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Method         : getjwtToken
   * Description    : Get token
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getjwtToken() {
    return this.http.get(this.APIEndpoint+"api/auth/user")
    .pipe(map((response:Response) => {
      let responseAny : any = response; 
      return responseAny;
    }),
    catchError((err:any) =>{
      throw(err)
    })
    );
  }

  /**
   * Method         : globalCustomerSearch
   * Description    : Globel Customer Search
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public globalCustomerSearch(params) {
    return this.http.post(this.APIEndpoint+"api/auth/customer/customer-list", params)
    .pipe(map((response:Response) => {
      let responseAny : any = response; 
      return responseAny;
    }),
    catchError((err:any) =>{
      throw(err)
    })
    );  
  }
}
