import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AlertService } from "./alert.service";
import { AuthJwtService } from "./auth-jwt.service";
import { LogoutService } from "./logout.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _authJwtService: AuthJwtService,
    private _logOutService: LogoutService,
    private _alertService: AlertService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem("_token") !== null)
      return this.isAuthorized(state);
    this._logOutService.LogOut();
    return false;
  }

  isAuthorized = (state: RouterStateSnapshot): boolean => {
    const userDetails = this._authJwtService.decodeAuthToken(
      localStorage.getItem("_token")
    );
    if (
      userDetails.user_group_id == 3 || //Service Technician
      userDetails.user_group_id == 4 || // Sales Representative
      userDetails.user_group_id == 23   // Technician
    ) {
      return this.isSalesNServicePrivilege(state);
    } else if (userDetails.user_group_id == 21) {
      return this.isCSAPrivilege(state);
    } else {
      return true;
    }
  };

  isSalesNServicePrivilege = (state: RouterStateSnapshot): boolean => {
    if (state.url.includes("dashboard-layout")) return true; //dashboard
    if (state.url.includes("tournament")) return true; // tournament
    if (state.url.includes("audit-layout")) return true; //audit
    //if (state.url.includes("payroll-layout")) return true; //payroll
    if (state.url.includes("tutorial-layout")) return true;
    if (state.url.includes("apps")) return true;
    if (state.url.includes("report")) return true;
    this._alertService.showAlert("No Privilege", "info");
    this._router.navigate(["dashboard-layout/dashboard"]);
    return false;
  };

  isCSAPrivilege = (state: RouterStateSnapshot): boolean => {
    if (state.url.includes("dashboard-layout")) return true; //dashboard
    if (state.url.includes("customers")) return true; // customer
    if (state.url.includes("report")) return true; // report
    if (state.url.includes("technicians")) return true; // Assign Routes
    if (state.url.includes("tutorial-layout")) return true; // tutorial
    if (state.url.includes("tournament")) return true; // tournament
    if (state.url.includes("setting-layout")) return true; // setting
    if (state.url.includes("apps")) return true; // App
    if (state.url.includes("notifications")) return true; // notifications
    if (state.url.includes("audit-layout")) return true; //audit
    //if (state.url.includes("payroll-layout")) return true; //payroll
    this._alertService.showAlert("No Privilege", "info");
    this._router.navigate(["dashboard-layout/dashboard"]);
    return false;
  };

}
