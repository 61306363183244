import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { tap, delay } from 'rxjs/operators';
import { LoaderService } from "./shared/services/loader.service";
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  public currentRoute     = '';
  public isHidden:boolean = false;
  //public isDarkMode = localStorage.getItem('_isDarkMode');
  public isDarkMode = 0;

  public loaderColor:any;
  public darkModeBGColor: any;
  public darkModeClass:any;

  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private _loaderService: LoaderService,
    private _themeService: ThemeService
  ) { }

  ngOnInit() {
    this._themeService.getDarkTheme().subscribe((value)=>{
      this.darkModeBGColor = value==1?'#000':'#fff';
      this.loaderColor = value==1?'5px solid #fff':'5px solid #333';
      this.darkModeClass = value==1?'darkmode':'lightmode';
    });
    //console.log(this.isDarkMode);
    this.router.events
      .subscribe(event => {
        let currentRoute = this.route.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.currentRoute = currentRoute.url['_value'][0]['path'];
        // console.log(this.currentRoute);
      });
  }

  ngAfterViewInit(){
    // this._loaderService.castLoader.subscribe(loader => this.loader(loader));

    // add delay in subscribtion to facilitate change detection
    this._loaderService.castLoader
      .pipe(
          delay(0),
          tap((loader) => this.loader(loader))
      ).subscribe();
    this._themeService.isThemeDarkObj.pipe(
       delay(0),
       tap((themedata)=> this.loadDarktheme(themedata))
    ).subscribe();
  }

  public loader(data) {
    if(data ==='showloader'){
      this.isHidden = true;
    }else if(data ==='hideloader'){
      this.isHidden = false;
    }
  }

  public loadDarktheme(data) {
    if(data == 1){
      this.isDarkMode = 1;
    }else {
      this.isDarkMode = 0;
    }
  }

  isHeaderFooterVisible = () :boolean => {
    //console.log(this.currentRoute);
    if(this.currentRoute == '') return false
    if(this.currentRoute == 'login') return false
    if(this.currentRoute == 'confirm-Email') return false
    if(this.currentRoute == 'colosseum-view') return false
    if(this.currentRoute == 'ludi-games-view') return false
    if(this.currentRoute == 'consolation-view') return false
    return true
  }

  isFullPageView = () :boolean => {
    if(this.currentRoute === 'login') return true
    if(this.currentRoute === 'confirm-Email') return true
    return false
  }

}
