import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private _toastr: ToastrService
  ) { }

  /**
   * Method         : showAlert
   * Description    : Show Alert message
   * Date           : 2020-01-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public showAlert(msg, type) {
    switch (type) {
      case 'success':
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.success(elm, "Success!!");
          });
        } else {
          this._toastr.success(msg, 'Success!!')
        }
        break;
      case 'error':
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.error(elm, "Error!");
          });
        } else {
          this._toastr.error(msg, "Error!");
        }
        break;
      case 'info':
        this._toastr.info(msg, 'Info')
        break;
      default:
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.info(elm, "Info");
          });
        } else {
          this._toastr.info(msg, "Info");
        }
        break;
    }
  }
}
