import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  public APIEndpoint = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Method         : Login
   * Description    : get access tocken
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public authToken(params) {
    const formData = new FormData();
    formData.append('username', params.username);
    formData.append('password', params.password);
    formData.append('grant_type', params.grant_type);
    formData.append('client_id', params.client_id);
    formData.append('client_secret', params.client_secret);
    formData.append('provider', params.provider);
    // return this.http.post(this.APIEndpoint + "oauth/token", formData)
    return this.http.post(this.APIEndpoint + "api/auth/login", formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );

    // const HEADERS = new HttpHeaders({ 'Content-Type': 'application/json' });
    // return this.http.post("http://www.app-backend.dev/oauth/token", params, { withCredentials: true, headers: HEADERS })
    /* .catch((err)=>{
      return Observable.throw(err);
    }); */

  }

  /**
 * Method         : getjwtToken
 * Description    : Get token
 * Date           : 2018-11-22
 * Author         : Satyabrata Rakshit(Appsbee)
 */
  public getjwtToken() {
    return this.http.get(this.APIEndpoint + "api/auth/user")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


}
