import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  public APIEndpoint = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Method         : getZoneList
   * Description    : Get list of Zone
   * Date           : 2018-11-12
   * Author         : Tushar Majumder(Appsbee)
   */
  public getzoneList() {
    return this.http.get(this.APIEndpoint+'api/auth/zone')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getZoneDetails
   * Description    : Get specific zone Details by Admin
   * Date           : 2019-04-09
   * Author         : Tushar Majumder(Appsbee)
   */
  public getZoneDetails(id){
    return this.http.get(this.APIEndpoint+'api/auth/zone/' + id)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addZone
   * Description    : Add zone
   * Date           : 2019-04-10
   * Author         : Tushar Majumder(Appsbee)
   */
  public addZone(params){
    const formData = new FormData();
    formData.append('zone', params.zone);
    formData.append('zone_details', params.zone_details);
    
    return this.http.post(this.APIEndpoint+'api/auth/zone', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );   
  }

  public updateZone(params){
    const formData = new FormData();
    formData.append('zone', params.zone);
    formData.append('zone_details', params.zone_details);
    formData.append('is_active', params.is_active);
    formData.append('id', params.id);

    console.log(params);
   
    return this.http.post(this.APIEndpoint + 'api/auth/zone/update-zone', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
