import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";
import { ZoneService } from '../../zone-layout/_service/zone.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  public APIEndpoint = environment.baseUrl;
  public bSubject = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private _zoneService: ZoneService
  ) { }

  /**
   * Method         : getEmployeeList
   * Description    : Get list of Employee Lists by Admin
   * Date           : 2018-11-12
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getEmployeeList(status,tounamentStatus) {
    return this.http.get(`${this.APIEndpoint}api/auth/employee?status=${status}&game=${tounamentStatus}`)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getEmployeeDetails
   * Description    : Get specific Employee Details by Admin
   * Date           : 2018-11-12
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getEmployeeDetails(employeeId) {
    return this.http.get(this.APIEndpoint + 'api/auth/employee/' + employeeId)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addEmployee
   * Description    : Add Employee by Admin
   * Date           : 2018-11-12
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public addEmployee(params, isfileAdded, fileupload) {
    const formData = new FormData();
    formData.append('username', params.username);
    formData.append('password', params.password);
    formData.append('office_id', params.office_id);
    formData.append('zone_id', params.zone_id);
    formData.append('fname', params.fname);
    formData.append('lname', params.lname);
    formData.append('nickname', params.nickname);
    formData.append('user_group_id', params.user_group_id);
    formData.append('phone', params.phone);
    formData.append('email', params.email);
    formData.append('experience', params.experience);
    formData.append('licence_number', params.licence_number);

    formData.append('address', params.address);
    formData.append('city', params.city);
    formData.append('state', params.state);
    formData.append('zip', params.zip);
    formData.append('team_id', params.team_id);


    if (isfileAdded) {
      formData.append('pic', params.pic);
      formData.append('userImage', fileupload, fileupload.name);
    } else {
      formData.append('pic', '');
    }
    return this.http.post(this.APIEndpoint + 'api/auth/employee', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deleteEmployee
   * Description    : Delete Employee by Admin
   * Date           : 2018-11-12
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public deleteEmployee(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/employee/status-change', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateEmployee
   * Description    : Delete Employee by Admin
   * Date           : 2018-11-12
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public updateEmployee(params, isfileAdded, fileupload, lic_Data) {
    console.log(params);
    const formData = new FormData();
    formData.append('username', params.username);
    formData.append('password', params.password);
    formData.append('office_id', params.office_id);
    formData.append('viewable_office_ids', params.viewable_office_ids);
    formData.append('zone_id', params.zone_id);
    formData.append('fname', params.fname);
    formData.append('lname', params.lname);
    formData.append('nickname', params.nickname);
    formData.append('user_group_id', params.user_group_id);
    formData.append('phone', params.phone);
    formData.append('email', params.email);
    formData.append('experience', params.experience);
    formData.append('licence_number', params.licence_number);
    formData.append('id', params.id);
    formData.append('current_password', '');
    formData.append('new_password', params.new_password);
    formData.append('confirm_new_password', params.confirm_new_password);
    formData.append('reset_password_flag', params.reset_password_flag);

    formData.append('address', params.address);
    formData.append('city', params.city);
    formData.append('state', params.state);
    formData.append('zip', params.zip);
    formData.append('lat', params.lat);
    formData.append('lon', params.lon);
    //UDID
    formData.append('ipad_udid_no', params.ipad_udid_no);
    formData.append('iphone_udid_no', params.iphone_udid_no);
    //team
    formData.append('team_id', params.team_id);
    //active
    formData.append('is_active', params.is_active);
    //Colosseum
    formData.append('is_include_colessum', params.is_include_colessum);
    //ludi games
    formData.append('is_include_ludi_games', params.is_include_ludi_games);
    //pr_atlanta_id
    formData.append('pr_atlanta_id', params.pr_atlanta_id);
    //pr_dallas_id
    formData.append('pr_dallas_id', params.pr_dallas_id);
    //pr_houston_id
    formData.append('pr_houston_id', params.pr_houston_id);
    //pr_greenville_id
    formData.append('pr_greenville_id', params.pr_greenville_id);
    //pr_testing_sandbox_id
    formData.append('pr_testing_sandbox_id', params.pr_testing_sandbox_id);
    //pr_atlanta_west_id
    formData.append('pr_atlanta_west_id', params.pr_atlanta_west_id);

    formData.append('lics_office_list', params.lics_office_list);
    formData.append('sellingLicArrs', JSON.stringify(lic_Data));

    if (isfileAdded) {
      formData.append('pic', params.pic);
      formData.append('userImage', fileupload, fileupload.name);
    } else {
      formData.append('pic', '');
    }
    return this.http.post(this.APIEndpoint + 'api/auth/employee/update-employee', formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getserviceType
   */
  public geUserType() {
    return this.http.get(this.APIEndpoint + 'api/auth/user-group-list')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * ZoneListDetails
   */
  public ZoneListDetails() {
    return this._zoneService.getzoneList();
  }

  public getTeamList = (params) => {
    return this.http.post(`${this.APIEndpoint}api/auth/get-office-teams`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
