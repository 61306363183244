import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrontimeService {
  public APIEndpoint = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public getDateTime() {
    return this.http.get(this.APIEndpoint + "api/auth/get-cron-time")
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
