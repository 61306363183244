import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

import { LogoutService } from "../../shared/services/logout.service";
import { FooterService } from "./_service/footer.service";
import { AuthJwtService } from '../../shared/services/auth-jwt.service';
import { LoaderService } from '../../shared/services/loader.service';
import { Subscription, interval } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { SettingService } from '../../admin/setting-layout/_services/setting.service';
import { ThemeService } from '../../shared/services/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  public officeListArray:any            = [];
  public selectedOffice:any;
  public resource:any;
  public currentPath:string;
  public dataSub:any;
  public notification: Subscription;
  public newAlertCount:number = 0;
  public isChangeOfficeEnable: boolean = false;
  public isAddCustomerVisiable:any = 1;
  public checked: number = 0;
  public sessionDarkMode : number = 0;
  public themeDarkData : any;

  constructor(
    private _logoutService: LogoutService,
    public _footerService: FooterService,
    private _authJwtService: AuthJwtService,
    private _loaderService: LoaderService,
    private _router: Router,
    private _location: PlatformLocation,
    private _settingService: SettingService,
    private _themeService: ThemeService
  ) { }

  ngOnInit() {
    this.getResourceDetails();
    if(this.resource != undefined && this.resource.user_group_id == 2) this.viewableOfficeList();
    else this.officeList();
    this.dataSub = this._loaderService.castLoader.subscribe(loader => this.reLoader(loader));
    this.themeDarkData = this._themeService.getDarkTheme();
  }

  /**
   * Method         : signOut
   * Description    : Sign Out from App
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public signOut() {
    this._logoutService.LogOut();
    localStorage.removeItem('previousUserDetails');
  }

  /**
   * Method         : officeList
   * Description    : get list of Office
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public officeList() {
    this.officeListArray        = [];
    this._footerService.getOfficeList().subscribe((data) =>{
      if(data.success == 1){
        data.officelist.forEach(elm => {
          if(elm.is_active == '1'){
            this.officeListArray.push(elm);
          }
        });
        this._footerService.officeData.next(this.officeListArray);
        this.setOfficeList();
      }else{
        console.log(data);
      }
    },
    (error) =>{
      console.log(error);
    });
  }
  /**
   * Method         : viewableOfficeList
   * Description    : get list of viewable Office
   * Date           : 2021-11-15
   * Author         : Saikat Pal(Appsbee)
  */
  public viewableOfficeList() {
    this.officeListArray        = [];
    this._footerService.getViewableOfficeList().subscribe((data) =>{
      console.log(data);
      if(data.success == 1){
        data.officelist.forEach(elm => {
          if(elm.is_active == '1'){
            this.officeListArray.push(elm);
          }
        });
        //this.setOfficeList();
        this.selectedOffice = this.resource.office_id;
      }
    },
    (error) =>{
      console.log(error);
    });
  }

    /* Created by : Saikat Pal
  */
  public onChangeViewableBranch = (id) => {
      if(id != this.resource.office_id) {
        this.isAddCustomerVisiable = 0;
      } else {
        this.isAddCustomerVisiable = 1;
      }
      this._footerService.bSubject.next(this.isAddCustomerVisiable);
  }


  /**
   * Method         : getResourceDetails
   * Description    : Logged in resource Details
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getResourceDetails() {
    if(localStorage.getItem('_token') !== null){
      const rawToken = localStorage.getItem('_token');
      this.resource = this._authJwtService.decodeAuthToken(rawToken);
      this._settingService.bSubject.subscribe(value => {
        this.resource = value;
      })
      if(this.resource.user_group_id !== 3 && this.resource.user_group_id !== 4){
        this.getInitialAlert();
        this.getAlartNotification();
      }
    }
  }

  /**
   * Method         : setOfficeList
   * Description    : set office List
   * Date           : 2018-12-10
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public setOfficeList() {
    if(this.resource.office_id !== null && this.resource.office_id !== undefined){
      if(localStorage.getItem('_usertoken') !== null){
        let OfficeId = parseInt(atob(localStorage.getItem('_usertoken')));
        if(OfficeId == 0){
          this.setOfficeForZonalLogedInResources();
        }else{
          this.selectedOffice = OfficeId;
        }
      }else{
        this.officeListArray.forEach(elm => {
          if(this.resource.office_id == elm.id){
            this.selectedOffice = elm.id;
          }
        });
      }
    }else{
      if(localStorage.getItem('_usertoken') !== null){
        let OfficeId = parseInt(atob(localStorage.getItem('_usertoken')));
        if(OfficeId == 0){
          this.setOfficeForZonalLogedInResources();
        }else{
          this.selectedOffice = OfficeId;
        }
      }
    }
  }

  /**
   * Method         : onChange
   * Description    : on change office
   * Date           : 2019-01-31
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onChange($event) {
    //console.log($event.id);

    let officeID = btoa($event.id);
    this.changedOffice($event.id);
    this.onChangeOffice(officeID)
    if(this.resource.user_group_id==2) {
      this.onChangeViewableBranch($event.id);
    }
  }

  /**
   * onChangeOffice
   */
  public onChangeOffice(officeID) {
    if(localStorage.getItem('_usertoken') !== null){
      localStorage.removeItem('_usertoken');
      localStorage.setItem("_usertoken", officeID);
    }else{
      localStorage.setItem("_usertoken", officeID);
    }
    const currentPath = location.hash.split('/').pop();
    if( currentPath == 'dashboard'){
      // this._loaderService.reloadOfficeList();
      this._loaderService.changeOffice()
    }else{
      this._router.navigate(['dashboard-layout/dashboard']);
      this.getInitialAlert();
      setTimeout(() => {
        this._loaderService.changeOffice();
      }, 800);
    }
  }

  /**
   * Method         : changedOffice
   * Description    : set office name in local storage
   * Date           : 2019-01-31
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public changedOffice(id) {
    this.officeListArray.forEach(elm => {
      if(id == elm.id){
        if(localStorage.getItem('_name') !== null){
          localStorage.removeItem('_name');
          localStorage.setItem("_name", elm.office_name);
        }else{
          localStorage.setItem("_name", elm.office_name);
        }
      }
    });
  }

  /**
   * getAlartNotification
   */
  public getAlartNotification() {
    this.notification = interval(5*60*1000).pipe(
      concatMap(()=>this._footerService.alertCount())
    ).subscribe(
      (resp)=>{
        if(resp.success == 1){
          this.newAlertCount = resp.data;
        }else{
          console.log(resp);
        }
      },
      (error)=>{
        this.notification.unsubscribe();
      }
    );
  }

  /**
   * getInitialAlert
   */
  public getInitialAlert() {
    this._footerService.alertCount().subscribe(
      (resp)=>{
        if(resp.success == 1){
          this.newAlertCount = resp.data;
        }else{
          console.log(resp);
        }
      }
    );
  }

  public onModeChange(current_mode) {
    console.log(current_mode);
    if(current_mode=='dark_mode') {
      this.checked = 1;
    } else {
      this.checked = 0;
    }
    console.log(this.checked);
    //this.sessionDarkMode = this.checked?1:0;
    /* ======= */
    if(localStorage.getItem('_isDarkMode') == "1"){
      localStorage.removeItem('_isDarkMode');
      localStorage.setItem("_isDarkMode", "0");
    }else{
      localStorage.setItem("_isDarkMode", "1");
    }
    this._themeService.setDarkTheme(this.checked);
    //this._themeService.isThemeDark.next(this.checked);
    /* ======== */
  }

  /**
   * Method         : reLoader
   * Description    : reload for any new data field when any office related date changes
   * Date           : 2019-01-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public reLoader(data) {
    if(data ==='reloadOfficeList'){
      this.officeList();
      this.getInitialAlert();
    }
    if(data ==='diffOfficeCustomer'){
      if(localStorage.getItem('setoffice') !== null){
        let officeID = btoa(localStorage.getItem('setoffice'));
        this.selectedOffice = parseInt(localStorage.getItem('setoffice'));
        /* ======= */
        if(localStorage.getItem('_usertoken') !== null){
          localStorage.removeItem('_usertoken');
          localStorage.setItem("_usertoken", officeID);
        }else{
          localStorage.setItem("_usertoken", officeID);
        }
        /* ======== */
        this.changedOffice(localStorage.getItem('setoffice'));
        localStorage.removeItem('setoffice');
      }
      this.getInitialAlert();
    }
  }

  setOfficeForZonalLogedInResources = () => {
    if(this.officeListArray.length > 0){
      this.selectedOffice = this.officeListArray[0].id;
      let officeID = btoa(this.selectedOffice);
      this.changedOffice(this.selectedOffice);
      this.onChangeOffice(officeID)
    }
  }

  ngOnDestroy(){
    this.dataSub.unsubscribe();
    if(this.notification)this.notification.unsubscribe();
  }
}
