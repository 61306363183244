import {HashLocationStrategy, Location, LocationStrategy} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS,  } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

/* npm */
import { AccordionModule, TimepickerModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
// import { EmbedVideo } from 'ngx-embed-video';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

/* App Component and App Routing  */
import { LayoutComponent } from './layout.component';
import { routing } from './layout.routing';

/* Component */
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component'

/* Modules */
import { SharedModule } from "./shared/shared.module";
import { UsersModule } from "./users/users.module";

/* Services */
import { LoaderService } from "./shared/services/loader.service";
import { AuthJwtService } from "./shared/services/auth-jwt.service";
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { LogoutService } from "./shared/services/logout.service";
import { DemoComponent } from './common/demo/demo.component';
import { ThemeService } from './shared/services/theme.service';
import { CrontimeService } from './shared/services/crontime.service';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    DemoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UsersModule,
    // EmbedVideo.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),  
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    LoaderService,
    ThemeService,
    AuthJwtService,
    LogoutService,
    Location,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    DatePipe,
    CrontimeService
  ],
  bootstrap: [LayoutComponent]
})
export class LayoutModule { }
