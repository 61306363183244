import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private _router: Router,
  ) { }
  
  /**
   * Method         : LogOut
   * Description    : Logged out from App
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public LogOut() {
    sessionStorage.removeItem('_authToken')
    localStorage.removeItem('_token');
    localStorage.removeItem('_usertoken');
    localStorage.removeItem('_name');
    this._router.navigate(['login']);
  }
}
