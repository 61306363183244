import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MapLoaderService } from '../../shared/services/map/mapload-service';

declare var google: any;

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit, AfterViewInit {

  public map: any;
  public wayPoints: any = [];
  public latlngs:any    = [];
  public color:string;

  constructor() { }

  ngOnInit() {
    this.dummyData();
  }

  ngAfterViewInit() {    
    MapLoaderService.load().then(() => {
      console.log('initial loding..');
      this.setmap();
    });
  }

  setmap = () =>{
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 32.40, lng: -117.16 },
      zoom: 2,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.setMarker(this.latlngs)
  }

  setMarker = (resp) =>{
    let latlngbounds = new google.maps.LatLngBounds();
    let temp = [];
    resp.map((element,i)=>{
      this.color = this.rendomColorGenerate();
      temp.push(this.color);
      // if(i == 1 ){
      element.map((elm, index)=>{
        let marker = new google.maps.Marker({
          icon: this.pinSymbol(this.color),
          animation: google.maps.Animation.DROP,
          position: { lat: Number(elm.lat), lng: Number(elm.lon) },
          map: this.map,
          label: {
            text: `${index +1}`,
            color: "#fff",
            fontWeight: "bold",
          },
          title: `${i}`
        });
      });
      this.map.fitBounds(latlngbounds);
      this.map.panToBounds(latlngbounds);
      this.setDirection(element);
      console.log(temp)
      // }
    });
  }

  setDirection = (resp) =>{
    this.wayPoints = [];
    resp.forEach((item, index) => {
      if (index > 0 && index < resp.length - 1) {
        this.wayPoints.push({ "location": { lat: Number(item.lat), lng: Number(item.lon) }, "stopover": true });
      }
    });
    var directionsService = new google.maps.DirectionsService;
    var directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: this.color
      },
      suppressMarkers: true,
      
    });
    directionsDisplay.setMap(this.map);
    var start = { lat: Number(resp[0].lat), lng: Number(resp[0].lon) };
    var end = { lat: Number(resp[resp.length - 1].lat), lng: Number(resp[resp.length - 1].lon) };
    var request = {
      origin: start,
      destination: end,
      waypoints: this.wayPoints,
      // optimizeWaypoints: false,
      // travelMode: google.maps.TravelMode.DRIVING
      travelMode: 'DRIVING'
    };
    directionsService.route(request, function (result: any, status: any) {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(result);
      }
    });
  }

  rendomColorGenerate = (): string => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  pinSymbol = (color) => {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 1,
      scale: 1,
      labelOrigin: new google.maps.Point(0, -27)
    };
  }

  dummyData = () =>{
    const routes = [[{"lat":"22.581117412238928","lon":"88.49830822254194"},{"lat":"22.59038880533529","lon":"88.51422914470209"},{"lat":"22.60167460705523","lon":"88.63596421832813"}],[{"lat":"22.577067831040402","lon":"88.47961087877525"},{"lat":"22.590177381187768","lon":"88.5183107962705"},{"lat":"22.589438895868145","lon":"88.52307201558392"}],[{"lat":"22.5780600374265","lon":"88.48697153086437"},{"lat":"22.58135021078158","lon":"88.47996861840261"},{"lat":"22.581542764598055","lon":"88.47894200290216"}]];
    this.latlngs = routes;
    console.log(this.latlngs);
  }

}
