import { Component, OnInit, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, } from '@angular/router';

import { ServiceHeaderService } from "./_services/service-header.service";
import { LoaderService } from "../../shared/services/loader.service";
import { AuthJwtService } from "../../shared/services/auth-jwt.service";
import { SettingService } from '../../admin/setting-layout/_services/setting.service';
import { LoginServiceService } from '../../login/login-front/_service/login-service.service';
import { ThemeService } from '../../shared/services/theme.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public currentPath                              = '';
  public resource:any;
  public customerList:any                         = [];
  public rawCustomerList:any                      = [];
  public stateListArray:any                       = [];
  public subscriptionListArray:any                = [];
  public appointmentListArray:any                 = [];
  public customerDetails:any                      = [];
  public customerInput:string                     = '';
  public tempSeleCust:number                      = -1;
  public previousSearchCustomer                   = '';
  isViewAsvisable: boolean = true;
  public themeDarkData :any;

  constructor(
    private elementRef:ElementRef,
    private route: ActivatedRoute,
    private _serviceHeaderService: ServiceHeaderService,
    private _loaderService: LoaderService,
    public _authJwtService: AuthJwtService,
    private _router: Router,
    public _settingService: SettingService,
    private _loginServiceService: LoginServiceService,
    private _themeService: ThemeService
  ) {  }

  ngOnInit() {
    // this.currentPath = this.route.root.url['_value'][0]['path'];
    this.themeDarkData = this._themeService.getDarkTheme();
    console.log(this.themeDarkData.value);
    this.getResourceDetails();
    this._router.events
      .subscribe(event => {
        let currentRoute = this.route.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.currentPath = currentRoute.url['_value'][0]['path'];
        // console.log(this.currentPath);

        /* On Navigation Route Loader Is Enabled  */
        switch (true) {
          case event instanceof NavigationStart: {
            this._loaderService.showLoader();
            break;
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this._loaderService.hideLoader();
            break;
          }
          case event instanceof NavigationEnd:{
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  ngAfterload() {
    var s3 = document.createElement("script");
    s3.type = "text/javascript";
    s3.src = "./assets/vendors/bower_components/bootstrap/dist/js/bootstrap.min.js";
    this.elementRef.nativeElement.appendChild(s3);

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "./assets/dist/js/jquery.slimscroll.js";
    this.elementRef.nativeElement.appendChild(s);

    var s1 = document.createElement("script");
    s1.type = "text/javascript";
    s1.src = "./assets/dist/js/init.js";
    this.elementRef.nativeElement.appendChild(s1);

    var s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.src = "./assets/custom/RoutesPro.js";
    this.elementRef.nativeElement.appendChild(s2);
  }

  ngAfterViewInit() {
    this.ngAfterload();
  }

  previousUserDetails;

  switchUser(){
    let params = {
      "user_id": this.resource.previousUserID
    }
    this._settingService.postSwitchUserApi(params).subscribe((res: any)=>{
      if(res.success == 1){
        this._settingService.isVisibleMenu = false;
        // localStorage.removeItem('_token');
        sessionStorage.removeItem('_authToken');
        this.setAccessToken(res);
        localStorage.removeItem('previousUserDetails');
        this._settingService.bSubject.next('');
        this.previousUserDetails = this._authJwtService.decodeAuthToken(res.previous_user);
        // window.location.reload();
        this._router.navigate(['dashboard-layout/dashboard'])
      }
    })
  }

  public setAccessToken(rawResponse) {
    const type = rawResponse.token_type;
    const token = rawResponse.access_token;
    let expires = new Date();
    expires.setDate( expires.getDate() + 1 );

    if (type !== '' && token !== '') {
      if (sessionStorage.getItem('_authToken')) {
        sessionStorage.removeItem('_authToken');
        const authAccessToken = `${type} ${token}`;
        sessionStorage.setItem('_authToken', authAccessToken);
        this.getResourceDetails();
      } else {
        const authAccessToken = `${type} ${token}`;
        sessionStorage.setItem('_authToken', authAccessToken);
        this.getResourceForMainUser();
      }
    }
  }

  public getResourceForMainUser() {
    this._loaderService.showLoader();
    this._loginServiceService.getjwtToken().subscribe(
      (resp:any) => {
        if(resp.success == 1){
          this._loaderService.hideLoader();
          this.resource = this._authJwtService.decodeAuthToken(resp.token);
          // this.resource['previousUserDetails'] = localStorage.getItem('previousUserDetails');
          this._settingService.bSubject.next(this.resource)
          /* Set Resource Token in session */
          if(localStorage.getItem('_token') !== null){
            localStorage.removeItem('_token');
            localStorage.setItem("_token", resp.token);
          }else{
            localStorage.setItem("_token", resp.token);
          }
          let officeID = btoa(resp.office_token);
          if(localStorage.getItem('_usertoken') !== null){
            localStorage.removeItem('_usertoken');
            localStorage.setItem("_usertoken", officeID);
          }else{
            localStorage.setItem("_usertoken", officeID);
          }
          // this.onLogedInNavigation(this.resource)
        }else{
          this._loaderService.hideLoader();
        }
      },
      (error) => {
        this._loaderService.hideLoader();
        console.log(error);
      }
    );
  }

  /**
   * Method         : getResourceDetails
   * Description    : Resource Details
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getResourceDetails() {
    console.log(this.resource);
    if(localStorage.getItem('_token') !== null){

      const rawToken = localStorage.getItem('_token');
      this.resource = this._authJwtService.decodeAuthToken(rawToken);
      console.log(this.resource);
      this.resource['previousUserDetails'] = localStorage.getItem('previousUserDetails');
      this.resource['previousUserID'] = localStorage.getItem('previousUserID');
      this._settingService.bSubject.next(this.resource)
      this._settingService.bSubject.subscribe(value => {
        this.resource = value;
        this.isViewAsvisable = true
      });
    }
  }


  /* @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);
    if(this.customerList.length > 0){
      if(event.code == "ArrowDown"){
        if(this.tempSeleCust < 14){
          this.tempSeleCust++;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
      }
      if(event.code == "ArrowUp"){
        if(this.tempSeleCust > 0){
          this.tempSeleCust--;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
        if(this.tempSeleCust == 0){
          this.tempSeleCust = 0;
          let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
          elmnt.scrollIntoView();
        }
      }
      if( event.code == 'NumpadEnter' || event.code == 'Enter'){
        let selectedCustomer = this.customerList[this.tempSeleCust];
        setTimeout(() => {
          this.customerList = [];
        }, 250);
        this.onSelectCustomer(selectedCustomer);
      }
    }
  }*/


  /**
   * Method         : onLocationFocus
   * Description    : On Focus search field
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onLocationFocus() {
    this.tempSeleCust = 0;
  }

  /**
   * Method         : onLocationBlur
   * Description    : On blur search field
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onLocationBlur() {
    this.tempSeleCust = -1;
  }

  /**
   * Method         : onArrowUp
   * Description    : on arrow up scroll div
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowUp() {
    if(this.customerList.length > 0){
      if(this.tempSeleCust > 0){
        this.tempSeleCust--;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
      if(this.tempSeleCust == 0){
        this.tempSeleCust = 0;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onArrowDown
   * Description    : on Arrow Down scroll div
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowDown() {
    if(this.customerList.length > 0){
      if(this.tempSeleCust < 14){
        this.tempSeleCust++;
        let elmnt = document.getElementById(`block_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

    /**
   * Method         : onArrowUpsm
   * Description    : on arrow up scroll div
   * Date           : 2019-03-25
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowUpsm() {
    if(this.customerList.length > 0){
      if(this.tempSeleCust > 0){
        this.tempSeleCust--;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
      if(this.tempSeleCust == 0){
        this.tempSeleCust = 0;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onArrowDownsm
   * Description    : on Arrow Down scroll div
   * Date           : 2019-03-25
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onArrowDownsm() {
    if(this.customerList.length > 0){
      if(this.tempSeleCust < 14){
        this.tempSeleCust++;
        let elmnt = document.getElementById(`blocksm_${this.tempSeleCust}`);
        elmnt.scrollIntoView();
      }
    }
  }

  /**
   * Method         : onSelectkeyB
   * Description    : on Select customer by keyboard
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onSelectkeyB() {
    if(this.customerList.length > 0){
      let selectedCustomer = this.customerList[this.tempSeleCust];
      setTimeout(() => {
        this.customerList = [];
      }, 250);
      this.onSelectCustomer(selectedCustomer);
    }
  }

  /**
   * Method         : onKeyUp
   * Description    : Search customer on every kyeup
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onKeyUp(event) {
    let inputData = event.target.value;
    if(this.previousSearchCustomer !== inputData){
      let splitedData = inputData.split('');
      if(splitedData.length > 1){
        let params                = {
          global_search           : true,
          orderBy                 :[{orderId: "id", dir: "desc"}],
          draw                    : 1,
          start                   : 0,
          length                  : 15,
          search_flag             : 'global',
          search                  :{
            value                 : inputData
          },
        }
        this._serviceHeaderService.globalCustomerSearch(params).subscribe((resp)=>{
          if(resp.success == 1){
            this.rawCustomer(resp.data);
            this.rawCustomerList         = resp.data;
            this.previousSearchCustomer  = inputData;
          }else{

          }
        },
        (error)=>{
          console.log(error);
        });
      }
      else{
        this.customerList = [];
        this.tempSeleCust = 0;
        this.previousSearchCustomer = '';
      }
    }else{

    }
  }

  /**
   * Method         : rawCustomer
   * Description    : assign to secounday customer array
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public rawCustomer(data) {
    this.customerList = [];
    data.forEach(elm => {
      this.customerList.push(elm)
    });
  }

  /**
   * Method         : closeCustomerSearch
   * Description    : on close customer list
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public closeCustomerSearch() {
    this.customerList = [];
    this.customerInput  = '';
    this.tempSeleCust = 0;
    this.previousSearchCustomer = '';
  }

  /**
   * Method         : onSelectCustomer
   * Description    : on Select Customer
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public onSelectCustomer(item) {
    // this._loaderService.showLoader();
    let id = item.office.id;
    this.tempSeleCust = 0;
    this.customerList = [];
    this.customerInput= '';
    this.previousSearchCustomer = '';
    let params = {
      id          : item.id,
      offChanged  : false,
      type        : 'editCustomer'
    }
    if(localStorage.getItem('_usertoken') !== null){
      let currentOffice = parseInt(atob(localStorage.getItem('_usertoken')));
      if(currentOffice == item.office.id){
        localStorage.setItem("custDetails", JSON.stringify(params));
        this.routetoCustomer();
      }else{
        let confirmMsg = confirm(`Are you want to switch ${item.office.office_name} Branch`);
        if(confirmMsg == true){
          params.offChanged = true;
          localStorage.setItem("custDetails", JSON.stringify(params));
          localStorage.setItem("setoffice", id);
          this._loaderService.diffOfficeCustomer();
          this.routetoCustomer();
        }else{
          this._loaderService.hideLoader();
        }
      }
    }else{
      // params.offChanged = true;
      // localStorage.setItem("custDetails", JSON.stringify(params));
      // localStorage.setItem("setoffice", id);
      // this.routetoCustomer();
      // this._loaderService.diffOfficeCustomer();
      // this.getCustomerDetails(item.id, template);
    }
  }

  /**
   * Method         : routetoCustomer
   * Description    : route to customer page of specific branck
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public routetoCustomer() {
    const currentPath = location.hash.split('/').pop();
    if( currentPath == 'customers-list'){
      this._loaderService.customerDetail();
      // this._router.navigate(['customers/customers-list']);
    }else{
      this._router.navigate(['customers/customers-list']);
    }
  }

  onClickOctPayroll(){
    this._router.navigate(['payroll-layout/payroll-oct-dashboard'])
  }
  onClickDecPayroll(){
    this._router.navigate(['payroll-layout/payroll-dec-dashboard'])
  }
  onClickAprPayroll(){
    this._router.navigate(['payroll-layout/payroll-apr-dashboard'])
  }
  onClickOctReport(){
    this._router.navigate(['payroll-layout/payroll-oct-report'])
  }
  onClickDecReport(){
    this._router.navigate(['payroll-layout/payroll-dec-report'])
  }
  onClickAprReport(){
    this._router.navigate(['payroll-layout/payroll-apr-report'])
  }
  onClickOctNotification(){
    this._router.navigate(['/payroll-layout/payroll-notification'])
  }
  onClickDecNotification(){
    this._router.navigate(['/payroll-layout/dec-payroll-notification'])
  }
  onClickAprNotification(){
    this._router.navigate(['/payroll-layout/apr-payroll-notification']);
  }
  onClickPestControl(){
    this._router.navigate(['/report/pestcontrol-sales-report'])
  }
  onClickTermite(){
    this._router.navigate(['/report/termite-sales-report'])
  }
}
