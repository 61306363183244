import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  //white: string = '#ffffff';
  //black: string = '#141313';

  isThemeDark = new BehaviorSubject<number>(1);
  isThemeDarkObj = this.isThemeDark.asObservable();

  constructor() { }

  public setDarkTheme(data: number) {

    this.isThemeDark.next(data);

  }

  public getDarkTheme() {
    return this.isThemeDark;
  }

}
