<!-- <footer class="footer pl-30 pr-30"> -->
<footer class="footer" [ngClass]="[themeDarkData.value==1 ? 'dark-mode' : 'light-mode']">
  <div class="container">
    <div class="row hidden-xs hidden-sm">
      <div class="col-xs-7">
        <div id="webView">
          <a (click)="signOut()">
            <strong>Sign Out</strong>
          </a>
          <a [routerLink]="['setting-layout/settings']"
            *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
            <strong>Settings</strong>
          </a>
          <a [routerLink]="['notifications/customer-list']"
            *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
            <strong>Alerts
              <span class="badge">{{newAlertCount}}</span>
            </strong>
          </a>
          <a [routerLink]="['apps/details']">
            <strong>App Download</strong>
          </a>
          <a (click)="onModeChange('light_mode')" *ngIf="themeDarkData.value==1||themeDarkData.value=='1'">
            <strong>Light Mode</strong>
          </a>
          <a (click)="onModeChange('dark_mode')" *ngIf="themeDarkData.value==0||themeDarkData.value=='0'">
            <strong>Dark Mode</strong>
          </a>
        </div>
      </div>
      <!-- <div class="col-xs-2">
        <div class="row" style="padding: 16px;">
          <input type="checkbox" id="toggle-button-checkbox" (change)="darkMode()" value="checked">
          <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
          <div class="toggle-button-text">
            <div class="toggle-button-text-on">ON</div>
            <div class="toggle-button-text-off">OFF</div>
          </div>
        </div>
      </div> -->
      <div class="col-xs-3">
        <div class="row officediv">
          <div class="col-sm-12" *ngIf="officeListArray?.length > 0">
            <ng-select [items]="officeListArray" [disabled]='isChangeOfficeEnable' [multiple]="false"
              [closeOnSelect]="true" [clearable]="false" bindLabel="office_name" bindValue="id" [searchable]="true"
              placeholder="Select Office" [(ngModel)]="selectedOffice" (change)="onChange($event)">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row visible-xs visible-sm">
      <div class="col-xs-3">
        <div class="btn-group" dropdown [dropup]="true">
          <a id="button-basic" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown-basic">
            <img src="./../../../../assets/dist/img/plus-outline.png" alt="Menu" width="100%">
          </a>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
              <a class="dropdown-item" [routerLink]="['setting-layout/settings']">
                <i class="fa fa-gear"></i>
                Settings
              </a>
            </li>
            <li class="divider dropdown-divider" *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
            </li>
            <li role="menuitem" *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
              <a class="dropdown-item" [routerLink]="['notifications/customer-list']">
                <i class="fa fa-gear"></i>
                Alerts
                <span class="badge">{{newAlertCount}}</span>
              </a>
            </li>
            <li class="divider dropdown-divider" *ngIf="resource?.user_group_id !== 3 && resource?.user_group_id !== 4">
            </li>
            <li role="menuitem">
              <a class="dropdown-item" [routerLink]="['apps/details']">
                <i class="fa fa-gear"></i>
                App Download
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="signOut()">
                <i class="fa fa-sign-out"></i>
                Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-9">
        <div class="row officediv">
          <div class="col-sm-6"></div>
          <div class="col-sm-6" *ngIf="officeListArray?.length > 0">
            <ng-select [items]="officeListArray" [multiple]="false" [closeOnSelect]="true" [clearable]="false"
              bindLabel="office_name" bindValue="id" [searchable]="true" placeholder="Select Office"
              [(ngModel)]="selectedOffice" (change)="onChange($event)">
            </ng-select>
          </div>
        </div>
      </div>
    </div>

  </div>
</footer>
