import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* npm */
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

/* Pipes */
import { AnthemDateFormaterPipe } from './pipes/anthem-date-formater/anthem-date-formater.pipe';

/* Directives */
import { NumberOnlyDirective } from './directives/allow-number-only/number-only.directive';
import { PhoneFormatDirective } from './directives/us-phone-format/phone-format.directive';
import { IntegerOnlyDirective } from "./directives/allow-integer-only/integer-only.directive";
import { HighlightPipe } from './directives/search-highlight/highlight.pipe';
import { DropdownDirective } from "./directives/dropdown.directive";
import { CopyClipboardDirective } from './directives/copy-clipboard/copy-clipboard.directive';

/* Components */
import { PiechartComponent } from './charts/piechart/piechart.component';
import { AreachartComponent } from './charts/areachart/areachart.component';
import { ColumnChartComponent } from './charts/column-chart/column-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BraintreeLayoutComponent } from './braintree-layout/braintree-layout.component';
import { PaymentLayoutComponent } from "./payment-layout/payment-layout.component";
import { MapViewComponent } from './map-view/map-view.component';
import { SubLoaderComponent } from './sub-loader/sub-loader.component';
import { TournamentContentComponent } from './popover/tournament-content/tournament-content.component';
import { AgingDetailsComponent } from './popover/aging-details/aging-details.component';
import { ActiveAgingDetailsComponent } from './popover/active-aging-details/active-aging-details.component';
import { DataTablesModule } from 'angular-datatables';
import { AllowMinusDirective } from './directives/allow-integer-only/allow-minus.directive';
import { AllowMinusDecimalDirective } from './directives/allow-integer-only/allow-minus-decimal.directive';
/* Services */


@NgModule({
  declarations: [
    PiechartComponent,
    AreachartComponent,
    NumberOnlyDirective,
    IntegerOnlyDirective,
    HighlightPipe,
    DropdownDirective,
    ColumnChartComponent,
    BarChartComponent,
    LineChartComponent,
    PhoneFormatDirective,
    BraintreeLayoutComponent,
    PaymentLayoutComponent,
    MapViewComponent,
    CopyClipboardDirective,
    SubLoaderComponent,
    AnthemDateFormaterPipe,
    TournamentContentComponent,
    AgingDetailsComponent,
    ActiveAgingDetailsComponent,
    AllowMinusDirective,
    AllowMinusDecimalDirective
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    DataTablesModule
  ],
  providers:[  ],
  exports:[
    PiechartComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AreachartComponent,
    NumberOnlyDirective,
    AllowMinusDirective,
    AllowMinusDecimalDirective,
    IntegerOnlyDirective,
    HighlightPipe,
    ColumnChartComponent,
    NgSelectModule,
    BarChartComponent,
    LineChartComponent,
    PhoneFormatDirective,
    BraintreeLayoutComponent,
    MapViewComponent,
    CopyClipboardDirective,
    SubLoaderComponent,
    AnthemDateFormaterPipe,
    TournamentContentComponent,
    AgingDetailsComponent,
    ActiveAgingDetailsComponent,
  ]
})
export class SharedModule { }
