import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";
import { OfficeService } from '../../office-layout/_service/office.service';
import { ServiceTypeService } from '../../service-type-layout/_service/service-type.service';
import { EmployeeService } from '../../employee-layout/_service/employee.service';
import { ZoneService } from '../../zone-layout/_service/zone.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  public APIEndpoint = environment.baseUrl;

  switchUser;
  previousUser;

  bSubject = new BehaviorSubject(""); 
  isVisibleMenu:boolean=false;

  constructor(
    private http: HttpClient,
    private _officeService: OfficeService,
    private _serviceTypeService: ServiceTypeService,
    private _employeeService: EmployeeService,
    private _zoneService: ZoneService
  ) { }

  /**
   * getTargetPestList
   */
  public getTargetPestList() {
    return this.http.get(this.APIEndpoint + "api/auth/office-target-pest")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public getAllTargetPestList() {
    return this.http.get(this.APIEndpoint + "api/auth/office-target-pest/all")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * updateTargetPest
   */
  public updateTargetPest(params) {
    return this.http.post(this.APIEndpoint + "api/auth/office-target-pest/update-target-pest", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * addTargetPest
   */
  public addTargetPest(params) {
    return this.http.post(this.APIEndpoint + "api/auth/office-target-pest", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * cancelReasonList
   */
  public cancelReasonList() {
    return this.http.get(this.APIEndpoint + "api/auth/cancel-reason")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * updateCancelReason
   */
  public updateCancelReason(params) {
    return this.http.post(this.APIEndpoint + "api/auth/cancel-reason/update-cancel-reason", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * addCancelReason
   */
  public addCancelReason(params) {
    return this.http.post(this.APIEndpoint + "api/auth/cancel-reason", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : officeDetails
   * Description    : get Logged In Office details
   * Date           : 2019-02-14
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public officeDetails(params) {
    return this._officeService.getOfficeDetails(params);
  }

  /**
   * stateList
   */
  public stateList() {
    return this._officeService.getStateList();
  }

  /**
   * updateOffice
   */
  public updateOffice(params, uploadedFile) {
    return this._officeService.updateOfficeDetails(params, uploadedFile);
  }

  /**
   * Method         : getTutorialList
   * Description    : get Tutorials list
   * Date           : 2019-02-14
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getTutorialList() {
    return this.http.get(this.APIEndpoint + "api/auth/office-tutorial")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deleteTutorial
   * Description    : delete any Tutorials
   * Date           : 2019-02-14
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public deleteTutorial(params) {
    return this.http.post(this.APIEndpoint + "api/auth/office-tutorial/delete", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addTutorial
   * Description    : add any Tutorials
   * Date           : 2019-02-14
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public addTutorial(params) {
    return this.http.post(this.APIEndpoint + "api/auth/office-tutorial", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : editTutorial
   * Description    : edit Tutorials
   * Date           : 2019-02-15
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public editTutorial(params) {
    return this.http.post(this.APIEndpoint + "api/auth/office-tutorial/update", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getTemplateList
   * Description    : Contract Template List
   * Date           : 2019-03-03
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getTemplateList() {
    return this.http.get(this.APIEndpoint + "api/auth/contract-template")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateTemplate
   * Description    : updare Contract Template
   * Date           : 2019-03-06
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public updateTemplate(params) {
    return this.http.post(this.APIEndpoint + "api/auth/contract-template/update-template", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addTemplate
   * Description    : Add New Contract Template
   * Date           : 2019-03-06
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public addTemplate(params) {
    return this.http.post(this.APIEndpoint + "api/auth/contract-template", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : serviceList
   * Description    : All Service Type List
   * Date           : 2019-03-06
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public serviceList() {
    return this._serviceTypeService.getServiceTypeList();
  }

  /**
   * Method         : templateUploadedImages
   * Description    : All Uploaded Images for template
   * Date           : 2019-04-05
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public templateUploadedImages(params) {
    return this.http.post(this.APIEndpoint + "api/auth/contract-template/get-contract-template-images", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : uploadImage
   * Description    : Upload image for template
   * Date           : 2019-04-05
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public uploadTemplateImage(params) {
    const formData = new FormData();
    formData.append('contract_template_id', params.contract_template_id);
    formData.append('upload_image', params.upload_image, params.upload_image.name);
    return this.http.post(this.APIEndpoint + "api/auth/contract-template/upload-image", formData)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deleteTemplateImage
   * Description    : delete template image
   * Date           : 2019-04-05
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public deleteTemplateImage(params) {
    return this.http.post(this.APIEndpoint + "api/auth/contract-template/delete-contract-template-images", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getEmployeeDetails
   * Description    : get Full Resource Details
   * Date           : 2019-04-15
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getEmployeeDetails(id) {
    return this._employeeService.getEmployeeDetails(id);
  }

  /**
   * ZoneListDetails
   */
  public ZoneListDetails() {
    return this._zoneService.getzoneList();
  }

  /**
   * updateEmployee
   */
  public updateEmployee(params,isfileAdded, fileupload, lic_Data) {
    return this._employeeService.updateEmployee(params,isfileAdded, fileupload, lic_Data);
  }

  /**
   * Method         : notificationLists
   * Description    : Notification Lists
   * Date           : 2019-09-05
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public notificationLists() {
    return this.http.post(this.APIEndpoint + "api/auth/notification-list", {})
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateNotification
   * Description    : Update Notification
   * Date           : 2019-09-05
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public updateNotification(params) {
    return this.http.post(this.APIEndpoint + "api/auth/notification/update-notification", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getCancelPredictionData
   */
  public getCancelPredictionData(params) {
    return this.http.post("http://3.232.202.143:5000/predict_comment", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getRouteSettingData
   */
  public getRouteSettingData(){
    return this.http.get(this.APIEndpoint + "api/auth/routeSettings")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public updateRouteSetting(params){
    return this.http.post(this.APIEndpoint + "api/auth/routeSettings", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public getAppReleaseList(){
    return this.http.get( `${this.APIEndpoint}api/auth/get-all-app-release`)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public onAddRelease(params){
    return this.http.post( `${this.APIEndpoint}api/auth/add-app-release`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public onUpdateRelease(params){
    return this.http.post( `${this.APIEndpoint}api/auth/update-app-release`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public getSwitchUserApi(){
    return this.http.get(`${this.APIEndpoint}api/auth/users`)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public postSwitchUserApi(params){
    return this.http.post(`${this.APIEndpoint}api/auth/users/switch`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

}
