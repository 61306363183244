<div class="container">
  <!-- Top Menu Items -->
  <nav class="navbar navbar-inverse navbar-fixed-top navigationBar">
    <div class="nav-wrap">
      <div class="mobile-only-brand pull-left">
        <div class="nav-header pull-left">
          <div class="logo-wrap">
            <a [routerLink]="['dashboard-layout/dashboard']" routerLinkActive="active">
              <span class="brand-text"><img src="../../../../assets/dist/img/R_logo.png" alt="AnthemPest"
                  height="30px" /></span>
            </a>
          </div>
        </div>
        <a id="toggle_nav_btn" class="toggleNavBtn toggle-left-nav-btn inline-block ml-20 pull-left"
          href="javascript:void(0);"><i class="zmdi zmdi-menu"></i>
        </a>
        <a id="toggle_mobile_nav" class="mobile-only-view" href="javascript:void(0);"><i class="zmdi zmdi-more"></i></a>
      </div>
    </div>
  </nav>
  <!-- /Top Menu Items -->

  <!-- Left Sidebar Menu -->
  <div class="fixed-sidebar-left">
    <a *ngIf="resource?.fname && resource?.previousUserDetails" class="switch-user" (click)="switchUser()">you are
      viewing as {{resource?.fname}}. View back as {{resource?.previousUserDetails}}</a>
    <ul class="nav navbar-nav side-nav nicescroll-bar" [ngClass]="{'side-nav-darkmode' : themeDarkData.value==1}">
      <li class="pull-left navigationImg">
        <a [routerLink]="['dashboard-layout/dashboard']" routerLinkActive="active" id="navImage">
          <span class="brand-text">
            <img class="brand-img" src="../../../../assets/dist/img/R_logo.png" alt="AnthemPest" height="35px" />
          </span>
        </a>
      </li>
      <li class="navigation-header">
        <span>Main</span>
        <hr />
      </li>
      <li class="visible-xs" *ngIf="resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 23
      ">
        <form id="searchForm">
          <div class="form-group">
            <label class="sr-only" for="globalCustomerSearch">Global Customer Search</label>
            <div class="dropdown col-lg-12 searchIconXS">
              <input type="text" class="search" name="globalCustomerSearch" id="globalCustomerSearch"
                (keyup)="onKeyUp($event)" [(ngModel)]="customerInput" placeholder="Search Customer"
                (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUpsm()"
                (keydown.ArrowDown)="onArrowDownsm()" (keydown.Enter)="onSelectkeyB()"
                (keydown.NumpadEnter)="onSelectkeyB()" />
            </div>
            <div class="dropdown-content" *ngIf="customerList?.length > 0">
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeCustomerSearch()">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
              <ul class="list-group">
                <a *ngFor="let item of customerList; let i = index">
                  <li class="list-group-item" (click)="onSelectCustomer(item)" [class.preSeleCust]="i == tempSeleCust"
                    id="blocksm_{{ i }}">
                    <div class="media">
                      <div class="media-body">
                        <h4 class="media-heading">
                          <b innerHTML="{{
                            item.first_name | highlight: customerInput
                          }} {{ item.last_name | highlight: customerInput }}"></b>
                          <span> [ {{ item.id }} ] </span>
                        </h4>
                        <p>
                          <b>{{ item.office.office_name }}</b>
                        </p>
                        <p>
                          <span innerHTML="{{
                            item.email | highlight: customerInput
                          }}"></span>
                        </p>
                        <p>
                          <span innerHTML="{{ item.city | highlight: customerInput }}"></span>
                        </p>
                        <p>
                          <span>{{ item.state }}</span>
                          <span>{{ item.zip }}</span>
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </form>
      </li>
      <!-- Dashboard -->
      <li>
        <a [routerLink]="['/dashboard-layout/dashboard']" routerLinkActive="active-page">
          <div class="pull-left">
            <i class="ti-book mr-20"></i><span class="right-nav-text">Dashboard</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <!--data-toggle="collapse" data-target="#dashboard" [class.active]="
      currentPath === 'dashboard' ||
      currentPath === 'termite-dashboard'"-->
        <!-- <ul id="dashboard" class="collapse collapse-level-1">
          <li>
            <a [routerLink]="['/dashboard-layout/dashboard']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Pest Control</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/dashboard-layout/termite-dashboard']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Termite</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
        </ul> -->

      </li>
      <!-- Hiring -->
      <li *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 21 &&
        resource?.user_group_id !== 23
      ">
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#hires" [class.active]="
          currentPath === 'hires-dashboard' ||
          currentPath === 'prospect-list' ||
          currentPath === 'contract-list' ||
          currentPath === 'hierarchy' ||
          currentPath === 'roster' ||
          currentPath === 'housing-list'
        ">
          <div class="pull-left">
            <i class="ti-book mr-20"></i>
            <span class="right-nav-text">Hiring</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <ul id="hires" class="collapse collapse-level-1">
          <li>
            <a [routerLink]="['/hires/hires-dashboard']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Dashboard</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/hires/prospect-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Prospects</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li *ngIf="
            resource?.user_group_id == 20 ||
            resource?.user_group_id == 1 ||
            resource?.user_group_id == 2 ||
            resource?.user_group_id == 22 ||
            resource?.user_group_id == 5 ||
            resource?.user_group_id == 15 ||
            resource?.user_group_id == 16 ||
            resource?.user_group_id == 17 ||
            resource?.user_group_id == 19
          ">
            <a [routerLink]="['/hires/contract-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Pending Contracts List</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li *ngIf="
            resource?.user_group_id == 1 ||
            resource?.user_group_id == 2 ||
            resource?.user_group_id == 20
          ">
            <a [routerLink]="['/hires/hierarchy']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Hierarchy</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/hires/roster']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Roster</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/hires/housing-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Housing</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
        </ul>
      </li>
      <!-- Employee -->
      <li *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 21 &&
        resource?.user_group_id !== 23
      ">
        <a [routerLink]="['/employee/list']" routerLinkActive="active">
          <div class="pull-left">
            <i class="ti-book mr-20"></i><span class="right-nav-text">Employee</span>
          </div>
          <div class="clearfix"></div>
        </a>
      </li>
      <!-- Location -->
      <li *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 21 &&
        resource?.user_group_id !== 23
      ">
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#location" [class.active]="
          currentPath === 'office-list' ||
          currentPath === 'zone-list' ||
          currentPath === 'team-list'
        ">
          <div class="pull-left">
            <i class="ti-book mr-20"></i>
            <span class="right-nav-text">Location</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <ul id="location" class="collapse collapse-level-1">
          <li>
            <a [routerLink]="['/office/office-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Offices</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/teams/team-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Teams</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
        </ul>
      </li>
      <!-- Services -->
      <li *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 21 &&
        resource?.user_group_id !== 23
      ">
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#service" [class.active]="
          currentPath === 'product-type-list' ||
          currentPath === 'service-type-list'
        ">
          <div class="pull-left">
            <i class="ti-book mr-20"></i>
            <span class="right-nav-text">Services</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <ul id="service" class="collapse collapse-level-1">
          <!-- <li>
          <a [routerLink]="['product/product-type-list']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Product</span></div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <li>
            <a [routerLink]="['/service/service-type-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Service Types</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
        </ul>
      </li>
      <!-- Reports -->
      <li>
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#report" [class.active]="
          currentPath === 'employee-report' ||
          currentPath === 'customer-report' ||
          currentPath === 'sales-report' ||
          currentPath === 'invoice-report' ||
          currentPath === 'customers-list' ||
          currentPath === 'payroll-notification' ||
          currentPath === 'payroll-oct-report' ||
          currentPath === 'payroll-dec-report' ||
          currentPath === 'payroll-oct-dashboard' ||
          currentPath === 'payroll-dec-dashboard'
        ">
          <div class="pull-left">
            <i class="ti-book mr-20"></i>
            <span class="right-nav-text">Reports</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <ul id="report" class="collapse collapse-level-1">
          <!-- <li>
          <a [routerLink]="['/report/employee-report']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Employee Reports</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/report/customer-report']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Customer Reports</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <li *ngIf="
            resource?.user_group_id !== 3 &&
            resource?.user_group_id !== 4 &&
            resource?.user_group_id !== 23
          ">
            <a [routerLink]="['/customers/customers-list']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Customers</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <!-- <li>
            <a [routerLink]="['/report/solar-customer-list']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Solar Customers</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li> -->
          <!-- <li>
          <a [routerLink]="['/report/sales-report']" routerLinkActive="active-page">
            <div class="pull-left">
              <i class="ti-book mr-20"></i><span class="right-nav-text">Sales Leaderboard</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <li>
            <!--href="javascript:void(0);" data-toggle="collapse" data-target="#sales-leaderboard"
              [class.active-page]="currentPath == 'pestcontrol-sales-report' || currentPath == 'termite-sales-report'"-->
            <a [routerLink]="['/report/pestcontrol-sales-report']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text">Sales Leaderboard</span>
              </div>
              <!-- <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div> -->
              <div class="clearfix"></div>
            </a>
            <!-- <ul id="sales-leaderboard" class="collapse collapse-level-2 dr-change-pos">
              <li>
                <a style="cursor: pointer;" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickPestControl()">Pest
                      Control</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a style="cursor: pointer;" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickTermite()">Termite</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
            </ul> -->
          </li>
          <!--Incentive-->
          <li *ngIf="resource?.user_group_id == 20 || resource?.id === 1 || resource?.id === 260">
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#incentive">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text">Incentive</span>
              </div>
              <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div>
              <div class="clearfix"></div>
            </a>
            <ul id="incentive" class="collapse collapse-level-2 dr-change-pos">
              <li>
                <a style="cursor: pointer;" routerLinkActive="active-page" [routerLink]="['/incentive/prize-list']">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Prize List</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a style="cursor: pointer;" routerLinkActive="active-page"
                  [routerLink]="['/incentive/incentive-user-list']">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Report</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/audit-layout/audit']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Audit Trail</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <!-- <li *ngIf="resource?.user_group_id == 1 || resource?.user_group_id == 20">
            <a [routerLink]="['/report/money-mondays']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Money Mondays</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li> -->

          <!--  resource.user_group_id === 1 -->
          <li *ngIf="resource?.user_group_id === 20 || resource?.id === 1 || resource?.id === 260">
            <!-- <a [routerLink]="['/payroll-layout/payroll-notification']" routerLinkActive="active">
            <div class="pull-left">
              <i class="ti-book mr-20"></i><span class="right-nav-text">PR Notification</span>
            </div>
            <div class="clearfix"></div>
            </a> -->
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#payroll"
              [class.active-page]="currentPath == 'payroll-oct-notification' || currentPath == 'payroll-dec-notification'">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text"> Payroll</span>
              </div>
              <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div>
              <div class="clearfix"></div>
            </a>
            <ul id="payroll" class="collapse collapse-level-2 dr-change-pos">
              <!-- payroll Notification-->
              <li *ngIf="resource?.user_group_id === 20 || resource?.id === 1 || resource?.id === 260">
                <!-- <a [routerLink]="['/payroll-layout/payroll-notification']" routerLinkActive="active">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">PR Notification</span>
              </div>
              <div class="clearfix"></div>
              </a> -->
                <a href="javascript:void(0);" data-toggle="collapse" data-target="#report-PR-Notification"
                  [class.active-page]="currentPath == 'payroll-oct-notification' || currentPath == 'payroll-dec-notification'">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i>
                    <span class="right-nav-text"> PR Notification</span>
                  </div>
                  <div class="pull-right">
                    <i class="ti-angle-down"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
                <ul id="report-PR-Notification" class="collapse collapse-level-2 dr-change-pos">
                  <li>
                    <!--routerLinkActive="active-page"-->
                    <a style="cursor: pointer;" href="javascript:void(0);" data-toggle="collapse"
                      data-target="#report-PR-test">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i>
                        <span class="right-nav-text" (click)="onClickOctNotification()">October Notification</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                    <ul id="report-PR-test" class="collapse collapse-level-3 dr-change-pos">
                      <li>
                        <a style="cursor: pointer;" routerLinkActive="active-page">
                          <div class="pull-left">
                            <i class="ti-book mr-20"></i>
                            <span class="right-nav-text">test Notification</span>
                          </div>
                          <div class="clearfix"></div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text"
                          (click)="onClickDecNotification()">December Notification</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text"
                          (click)="onClickAprNotification()">April
                          Notification</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- payroll Report-->
              <li *ngIf="resource?.user_group_id === 20 || resource?.id === 1 || resource?.id === 260">
                <a href="javascript:void(0);" data-toggle="collapse" data-target="#report-PR-Report"
                  [class.active-page]="currentPath == 'payroll-oct-report' || currentPath == 'payroll-dec-report'">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i>
                    <span class="right-nav-text"> PR Report Upload</span>
                  </div>
                  <div class="pull-right">
                    <i class="ti-angle-down"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
                <ul id="report-PR-Report" class="collapse collapse-level-2 dr-change-pos">
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i>
                        <span class="right-nav-text" (click)="onClickOctReport()">October Report</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickDecReport()">December
                          Report</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickAprReport()">April
                          Report</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- payroll dashboard -->
              <li>
                <a href="javascript:void(0);" data-toggle="collapse" data-target="#report-PR-dashboard"
                  [class.active-page]="currentPath == 'payroll-oct-dashboard' || currentPath == 'payroll-dec-dashboard'">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i>
                    <span class="right-nav-text"> Payroll Dashboard</span>
                  </div>
                  <div class="pull-right">
                    <i class="ti-angle-down"></i>
                  </div>
                  <div class="clearfix"></div>
                </a>
                <ul id="report-PR-dashboard" class="collapse collapse-level-2 dr-change-pos">
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickOctPayroll()">October
                          Payroll</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickDecPayroll()">December
                          Payroll</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" routerLinkActive="active-page">
                      <div class="pull-left">
                        <i class="ti-book mr-20"></i><span class="right-nav-text" (click)="onClickAprPayroll()">April
                          Payroll</span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a [routerLink]="['/report/knocking-area-report']" routerLinkActive="active" *ngIf="
            resource?.user_group_id == 20 ||
            resource?.user_group_id == 1 ||
            resource?.user_group_id == 2 ||
            resource?.user_group_id == 22 ||
            resource?.user_group_id == 5 ||
            resource?.user_group_id == 15 ||
            resource?.user_group_id == 16 ||
            resource?.user_group_id == 17 ||
            resource?.user_group_id == 19">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Knocking Area Report</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>

          <!-- *ngIf="resource.user_group_id === 2 || resource.user_group_id === 20 || resource.id === 1"-->

        </ul>
      </li>
      <!-- Routes -->
      <li *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 23
      ">
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#routes" [class.active]="
          currentPath == 'office-routes' ||
          currentPath === 'routes-map' ||
          currentPath == 'knocking-areas' ||
          currentPath == 'assign-route' ||
          currentPath == 'automatic-routeing' ||
          currentPath == 'knocking-areas-marker' ||
          currentPath == 'blackout-area-list'
        ">
          <div class="pull-left">
            <i class="ti-check-box mr-20"></i><span class="right-nav-text">Routes</span>
          </div>
          <div class="pull-right"><i class="ti-angle-down"></i></div>
          <div class="clearfix"></div>
        </a>
        <ul id="routes" class="collapse collapse-level-1">
          <!-- <li>
          <a [routerLink]="['routes/office-routes']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Routes for Offices</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <!-- <li>
          <a [routerLink]="['map/routes-map']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Routes Map</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <!-- <li *ngIf="
            resource.user_group_id == 20 ||
            resource.user_group_id == 1 ||
            resource.user_group_id == 22 ||
            resource.user_group_id == 2 ||
            resource.user_group_id == 21
          ">
          <a [routerLink]="['technicians/assign-route']" routerLinkActive="active-page">
            <div class="pull-left">
              <i class="ti-book mr-20"></i><span class="right-nav-text">Assign Routes to Technicians</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <!-- <li>
          <a [routerLink]="['routes/automatic-routeing']" routerLinkActive="active-page">
            <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Automatic Routeing</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->
          <li *ngIf="resource?.user_group_id !== 21">
            <a [routerLink]="['sales-zone/knocking-areas']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Sales Areas</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li *ngIf="resource?.user_group_id !== 21">
            <a [routerLink]="['sales-zone/knocking-areas-marker']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Sales Areas Marker</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li *ngIf="resource?.user_group_id == 20 || resource?.id === 1 || resource?.id === 260">
            <a [routerLink]="['sales-zone/knocking-areas-marker-view']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Blue Pin</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
          <li *ngIf="resource?.user_group_id !== 21">
            <a [routerLink]="['blackout-zone/blackout-area-list']" routerLinkActive="active-page">
              <div class="pull-left">
                <i class="ti-book mr-20"></i><span class="right-nav-text">Prohibited Zones</span>
              </div>
              <div class="clearfix"></div>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li>
      <a [routerLink]="['/customers/customers-list']" routerLinkActive="active">
        <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Customers</span></div>
        <div class="clearfix"></div>
      </a>
    </li> -->
      <!-- Tutorials -->
      <li>
        <a [routerLink]="['/tutorial-layout/tutorials-list']" routerLinkActive="active">
          <div class="pull-left">
            <i class="ti-book mr-20"></i><span class="right-nav-text">Tutorials</span>
          </div>
          <div class="clearfix"></div>
        </a>
      </li>
      <!-- Tournament -->
      <li>
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#tournament" [class.active]="
          currentPath === 'seeding' ||
          currentPath === 'colosseum' ||
          currentPath === 'consolation' ||
          currentPath === 'team-seeding' ||
          currentPath === 'ludi-games'
        ">
          <div class="pull-left">
            <i class="ti-book mr-20"></i>
            <span class="right-nav-text">Tournament</span>
          </div>
          <div class="pull-right">
            <i class="ti-angle-down"></i>
          </div>
          <div class="clearfix"></div>
        </a>
        <ul id="tournament" class="collapse collapse-level-1">
          <!-- Sales -->
          <li>
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#sales-tournament" [class.active-page]="
              currentPath === 'seeding' ||
              currentPath === 'colosseum' ||
              currentPath === 'consolation'
            ">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text">Sales Colosseum</span>
              </div>
              <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div>
              <div class="clearfix"></div>
            </a>
            <ul id="sales-tournament" class="collapse collapse-level-2 dr-change-pos">
              <li>
                <a [routerLink]="['/tournament/seeding']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Seeding</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a [routerLink]="['/tournament/colosseum']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Colosseum</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a [routerLink]="['/tournament/consolation']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Consolation</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
            </ul>
          </li>
          <!-- teams -->
          <li>
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#team-tournament" [class.active-page]="
              currentPath === 'team-seeding' || currentPath === 'ludi-games'
            ">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text">Teams Ludi Games</span>
              </div>
              <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div>
              <div class="clearfix"></div>
            </a>
            <ul id="team-tournament" class="collapse collapse-level-2 dr-change-pos">
              <li>
                <a [routerLink]="['/tournament/teams/team-seeding']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Seeding</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <li>
                <a [routerLink]="['/tournament/teams/ludi-games']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Ludi Games</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
              <!-- <li>
              <a
                [routerLink]="['/tournament/teams/consolation']"
                routerLinkActive="active-page"
              >
                <div class="pull-left">
                  <i class="ti-book mr-20"></i
                  ><span class="right-nav-text">Consolation</span>
                </div>
                <div class="clearfix"></div>
              </a>
            </li> -->
            </ul>
          </li>
          <!-- ufc -->
          <!-- <li>
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#sales-tournament" [class.active-page]="
              currentPath === 'seeding' ||
              currentPath === 'colosseum' ||
              currentPath === 'consolation' || currentPath === 'ufc/seeding'
            ">
              <div class="pull-left">
                <i class="ti-book mr-20"></i>
                <span class="right-nav-text">UFC291</span>
              </div>
              <div class="pull-right">
                <i class="ti-angle-down"></i>
              </div>
              <div class="clearfix"></div>
            </a>
            <ul id="sales-tournament" class="collapse collapse-level-2 dr-change-pos">
              <li>
                <a [routerLink]="['/tournament/ufc/seeding']" routerLinkActive="active-page">
                  <div class="pull-left">
                    <i class="ti-book mr-20"></i><span class="right-nav-text">Seeding</span>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
            </ul>
          </li> -->
        </ul>
      </li>
      <li class="hidden-xs hidden-sm" *ngIf="
        resource?.user_group_id !== 3 &&
        resource?.user_group_id !== 4 &&
        resource?.user_group_id !== 23
      ">
        <form id="searchForm">
          <div class="form-group">
            <label class="sr-only" for="globalCustomerSearch">Global Customer Search</label>
            <div class="dropdown searchIconXL">
              <input type="text" class="search" name="globalCustomerSearch" id="globalCustomerSearch"
                (keyup)="onKeyUp($event)" [(ngModel)]="customerInput" placeholder="Search Customer"
                (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()"
                (keydown.ArrowDown)="onArrowDown()" (keydown.Enter)="onSelectkeyB()"
                (keydown.NumpadEnter)="onSelectkeyB()" />
            </div>
            <div class="dropdown-content" *ngIf="customerList?.length > 0">
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeCustomerSearch()">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
              <ul class="list-group">
                <a *ngFor="let item of customerList; let i = index">
                  <li class="list-group-item" (click)="onSelectCustomer(item)" [class.preSeleCust]="i == tempSeleCust"
                    id="block_{{ i }}">
                    <div class="media">
                      <div class="media-body">
                        <h4 class="media-heading">
                          <b innerHTML="{{
                            item.first_name | highlight: customerInput
                          }} {{ item.last_name | highlight: customerInput }}"></b>
                        </h4>
                        <p>
                          <span innerHTML="{{
                            item.email | highlight: customerInput
                          }}"></span>
                        </p>
                        <p>
                          <span innerHTML="{{ item.city | highlight: customerInput }}"></span>
                          <span>, {{ item.state }}</span>
                          <span>, {{ item.zip }}</span>
                        </p>
                      </div>
                      <div class="media-right">
                        <p class="text-muted">
                          <span *ngIf="item.pestroutes_customer_id">[{{item.pestroutes_customer_id}}]</span>
                        </p>
                        <p>
                          <b>{{ item.office.office_name }}</b>
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
              </ul>
            </div>
            <!-- </div> -->
          </div>
        </form>
      </li>
    </ul>
  </div>
  <!-- /Left Sidebar Menu -->
</div>
