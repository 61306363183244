import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LayoutModule } from "./app/layout/layout.module";
import { environment } from './environments/environment';

if (environment.production || environment.anthem) {
  enableProdMode();
}
  
platformBrowserDynamic().bootstrapModule(LayoutModule)
  .catch(err => console.error(err));