import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthJwtService {

  public jwtHelper: JwtHelperService = new JwtHelperService();

  constructor() { }

  decodeAuthToken(token) {
    if (token) {      
      return this.jwtHelper.decodeToken(token);
    }
  }
}
