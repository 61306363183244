<!--Preloader-->
<div class="preloader-it" [class.loader]="isHidden" [ngClass]="[isDarkMode==1 ? 'drakmode' : 'lightmode']">
  <!-- <div class="la-anim-1"></div> -->
  <div class="signal" [style.border]="loaderColor"></div>
</div>
<!--/Preloader-->
<div class="wrapper theme-2-active navbar-top-light horizontal-nav custom-overflow" [ngClass]="[isDarkMode==1 ? 'wrapper-drakmode' : 'wrapper-lightmode']">
  <div *ngIf="isHeaderFooterVisible()" class="hidden-print">
    <app-header></app-header>
  </div>
  <div [ngClass]="[isDarkMode==1 ? 'page-wrapper page-wrapper-drakmode' : 'page-wrapper page-wrapper-lightmode']"  [class.wrapper-bg]="isFullPageView()">
    <!--class="page-wrapper"-->
    <div class="container">
      <router-outlet></router-outlet>
      <div *ngIf="isHeaderFooterVisible()" class="hidden-print">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
