import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";
import { OfficeService } from '../../../admin/office-layout/_service/office.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  public APIEndpoint = environment.baseUrl;
  public bSubject = new BehaviorSubject(1);
  public officeData = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
    private _officeService: OfficeService
  ) { }

  /**
   * getOfficeList
   */
  public getOfficeList() {
    return this._officeService.getOfficeList();
  }

  /**
   * getViewableOfficeList
  */
  public getViewableOfficeList() {
    return this._officeService.getViewableOfficeList();
  }

  /**
   * alertCount
   */
  public alertCount() {
    return this.http.post(this.APIEndpoint +"api/auth/appointment-alert-count", {})
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
